.rainbow-badge {
  position: fixed;
  bottom: 10px;
  right: 10px;
  border-radius: 100px;
  padding: 0px 8px;
  z-index: 1000;
  animation: rainbowCycle 10s linear infinite;
}

@keyframes rainbowCycle {
  0% {
    background-color: rgba(0, 0, 0, 0);
  }
  49% {
    background-color: rgba(0, 0, 0, 0);
  }
  50% {
    background-color: rgb(255, 255, 255);
  }
  53% {
    background-color: rgba(0, 0, 0, 0);
  }
  100% {
    background-color: rgba(0, 0, 0, 0);
  }
}

.rainbow-badge span {
  color: white;
  font-weight: bold;
}
